<script>
// Import des modules nécessaires
var moment = require('moment');
moment.locale('fr'); // Configuration de la localisation de moment.js en français

// Import des composants et des utilitaires nécessaires
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from '../../../helpers';
import { Erreur } from "../../../helpers/error";
import categorieCard from "../../../components/widgets/categorieCard.vue";

/**
 * Projects-list component
 */
export default {
  // Définition des métadonnées de la page
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcess,
    categorieCard,
  },

  data() {
    // Initialisation des données du composant
    return {
      titre: "",
      progress: 0,
      route1: "/projects/overviewCategorie/",
      route2: "/projects/updateCategories/",
      route3: "",
      process: false,
      modalOk: false,
      categ: {
        titre: "",
        description: "",
      },
      categories: [], // Tableau contenant les catégories
      moment: moment, // Objet moment pour la gestion des dates
      title: "Liste des catégories", // Titre de la page
      items: [
        {
          text: "Blogs",
          // href: "/projects/categories",
        },
        {
          text: "Catégories",
          active: true,
        },
      ],
    };
  },
  methods: {
    // Méthode pour afficher la modal
    showModal() {
      this.modalOk = true;
    },

    // Méthode pour gérer la sélection d'une image de catégorie
    imageCategorie(event) {
      this.imageCat = event.target.files[0];
    },

    // Méthode pour créer une nouvelle catégorie
    createCategorie() {
      const onUploadProgress = progressEvent => {
        this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        console.log(`Upload progress: ${this.progress}%`);
      };

      this.process = true;
      this.modalOk = false;
      this.titre = "Création de catégories";

      // Appel à l'API pour créer une nouvelle catégorie avec des données et une image
      Api.postFormData("/actualite/rest/categories/create-categorie", {
        file: this.imageCat,
        categorie_description: this.categ.description,
        categorie_name: this.categ.titre
      }, onUploadProgress)
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Catégorie ajoutée", "success");
          location.reload(); // Rechargement de la page pour afficher les mises à jour
        }).catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message); // Gestion de l'erreur
        });
    },
  },
  mounted() {
    // Action exécutée après que le composant a été monté

    // Appel à l'API pour récupérer la liste de toutes les catégories
    Api.get("/actualite/rest/categories/all")
      .then((res) => {
        this.categories = res.data.content;
      }).catch((error) => {
        Erreur.gestionErreur(error.message); // Gestion de l'erreur
      });
  }
};
</script>


<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loader-process :visible="process" :progress="progress" :title="titre" />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px;">
        <button type="submit" class="btn c2play-primary" style="position: relative; left: 90%;" @click="showModal">
          {{ $t("addButton") }}
        </button>
      </div>
    </div>
    <br>
    <div class="row">
      <categorieCard v-for="categ in categories.slice().reverse()" :key="categ.id" :categorie="categ">
      </categorieCard>
    </div>
    <!-- end row -->

    <!-- <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-success">
            <i class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
            Load more
          </a>
        </div>
      </div>
    </div> -->


    <b-modal v-model="modalOk" id="modal-xl" size="xl" :title="$t('pages.categorie.formulaire.title')"
      title-class="font-18" hide-footer>

      <div class="card-body">
        <form>
          <div class="form-group row mb-4">
            <label for="projectname" class="col-form-label col-lg-2">{{ $t('pages.categorie.formulaire.titre.label')
            }}</label>
            <div class="col-lg-10">
              <input id="projectname" name="projectname" type="text" class="form-control" v-model="categ.titre"
                :placeholder="$t('pages.categorie.formulaire.titre.placeholder')" />
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label col-lg-2">{{ $t('pages.categorie.formulaire.image')
            }}</label>
            <div class="col-lg-10">
              <input id="projectbudget" name="projectbudget" type="file" @change="imageCategorie($event)"
                class="form-control" accept=".png, .jpeg, .jpg" />
            </div>
          </div>

          <div class="form-group row">
            <label for="projectdesc" class="col-form-label col-lg-2">{{ $t('pages.categorie.formulaire.description.label')
            }}</label>
            <div class="col-lg-10">
              <textarea id="projectdesc" class="form-control" rows="3" v-model="categ.description"
                :placeholder="$t('pages.categorie.formulaire.description.placeholder')"></textarea>
            </div>
          </div>
        </form>
        <!-- <div class="row mb-4">
              <label class="col-form-label col-lg-2">Vidéo</label>
              <div class="col-lg-10">
                <DropZone @drop.prevent="drop" @change="selectedFile" />
                <span class="file-info">Fichier: {{ dropzoneFile.name }}</span>
              </div>
            </div> -->
        <!-- <h4>{{ tags }}</h4>
            <h4>{{ categories }}</h4> -->
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px;">
            <button type="submit" class="btn c2play-primary" style="position: relative; left: 30%;"
              @click="createCategorie">
              {{ $t("addButton") }}
            </button>
          </div>
        </div>



      </div>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>

<style></style>